<template lang="pug">
  v-autocomplete(
    themes="mp"
    v-model="internalValue"
    label="Версии"
    :items="items"
    :disabled="disabled"
    multiple
    item-value="id"
    item-text="name"
    :errors="selectedCount=== 0 ? 'Выберите хотя бы одну организацию' : null"
  )
    template(v-slot:prepend-item)
      v-list-item(@click="toggleAll")
        v-list-item-action
          v-simple-checkbox(
            @input="toggleAll"
            :value="!!selectedCount"
            :indeterminate="!selectedCount ? false : !isAllItemsSelected"
          )
        v-list-item-content
          v-list-item-title Выбрать все организации
    template(v-slot:selection="{ item, index }")
      div(
        v-if="index === 0"
        class="v-select__selection v-select__selection--comma"
      )
        span(v-if="isAllItemsSelected") Все организации
        span(
          v-else
          :style="{maxWidth: '120px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}"
          ) {{ item.name }}
      span(
        v-if="index === 1 && !isAllItemsSelected"
        class="grey--text caption"
      ) + еще {{internalValue.length - 1}}

</template>

<script>
// import { mapGetters } from 'vuex';

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
  },
  data() {
    return {
      internalValue: [{ id: null }],
      items: [],
    };
  },
  computed: {
    // ...mapGetters('ORGANIZATIONS_DICTIONARY', {
    //   items: 'organizations',
    // }),
    selectedCount() {
      return this.internalValue.length;
    },
    isAllItemsSelected() {
      return this.internalValue.length === this.items.length;
    },
  },
  watch: {
    value(value, oldValue) {
      if (value !== this.internalValue) {
        this.internalValue = value
          ? value.slice()
          : this.items.map(item => item.id);
      }
    },
    internalValue(value) {
      this.$emit('input', value.length === this.items.length ? null : value);
    },
  },
  created() {
    if (this.items.length !== 0) {
      this.internalValue = this.items.map(item => item.id);
    }
    const unwatch = this.$watch('items', () => {
      this.internalValue = this.value
        ? this.value.slice()
        : this.items.map(item => item.id);
      unwatch();
    });
  },
  methods: {
    toggleAll() {
      this.$nextTick(() => {
        if (this.isAllItemsSelected) {
          this.internalValue = [];
        } else {
          this.internalValue = this.items.map(item => item.id);
        }
      });
    },
  },
};
</script>
